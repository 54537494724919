import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { IoAlertCircleOutline } from 'react-icons/io5';
import ReactGA4 from 'react-ga4';
import { Button, Center, Text, Icon } from '@chakra-ui/react';

import googleAds from 'src/legacy/Utils/GoogleAds';
import { Inspectlet } from 'src/legacy/Utils/Inspectlet';

import { authApi, userApi } from 'src/api';
import { EllipsisLoading } from 'src/components/loadings';
import { useUserStore, useUrlsStore } from 'src/hooks/stores';
import { useFeatureFlagsQuery } from 'src/hooks/queries/featureFlag';
import { AuthModal } from 'src/modules/auth-modal';
import SentryProvider from 'src/modules/sentry';
import { ChakraProvider } from 'src/modules/chakra';
import { ModalProvider } from 'src/modules/modal';
import { ReactQueryProvider } from 'src/modules/react-query';

import { Integrations } from './appConstants';

import TagManager from 'react-gtm-module';

import './polyfills/fromEntries';

import './App.scss';
import 'src/legacy/Styles/Layout.scss';
import 'src/legacy/Styles/Reset.scss';
import 'src/legacy/Styles/Common.scss';

import ScrollToTop from 'src/components/ScrollToTop';

import { AppRoutes } from 'src/AppRoutes';
import { getCookie } from 'src/utils/cookie';

if (Integrations.GA_ID) ReactGA4.initialize(Integrations.GA_ID);

googleAds(Integrations.GADS_ID);

Inspectlet();

const tagManagerArgs = {
  gtmId: Integrations.GTM_ID,
};
TagManager.initialize(tagManagerArgs);
const usePanelOAuth = () => {
  // const { email, tkn, uid } = useParams();
  const [searchParams] = useSearchParams();

  const email = searchParams.get('email');
  const tkn = searchParams.get('tkn');
  const uid = searchParams.get('uid');

  const { setUser } = useUserStore();
  const isValidRedirect = React.useMemo(() => email && tkn, [tkn, email]);

  React.useEffect(() => {
    (() => {
      if (isValidRedirect) {
        const data = { email, id: Number(uid ?? 0), token: tkn };

        setUser(data);

        window.location.replace('/');
      }
    })();
  }, [tkn, isValidRedirect, setUser, email, uid]);

  return { isValidRedirect };
};

const useGoogleOAuth = () => {
  const [searchParams] = useSearchParams();
  const state = searchParams.get('state');
  const code = searchParams.get('code');
  const utm = getCookie('uticketUtm');

  const { setUser } = useUserStore();
  const isValidRedirect = React.useMemo(() => state && code, [code, state]);

  React.useEffect(() => {
    (async () => {
      if (isValidRedirect) {
        const respBag = await authApi.post(
          'oauth-signin',
          { state, code, utm },
          { params: { p: 'google' } },
        );
        setUser(respBag.data);
        window.location.replace(respBag.headers?.location ?? '/');
      }
    })();
  }, [code, isValidRedirect, setUser, state, utm]);

  return { isValidRedirect };
};

const useWirecardOAuth = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const { wirecard } = useUrlsStore();
  const isValidRedirect = React.useMemo(
    () => code && ['/me/account', '/me/events'].includes(location.pathname),
    [code, location.pathname],
  );

  React.useEffect(() => {
    (async () => {
      if (isValidRedirect) {
        await userApi.put('me/account', `"${code}"`);
        window.location.replace(wirecard);
      }
    })();
  }, [code, isValidRedirect, wirecard]);

  return { isValidRedirect };
};

const Pages = () => {
  const googleOAuth = useGoogleOAuth();
  const wirecardOAuth = useWirecardOAuth();
  const panelOAuth = usePanelOAuth();
  const featureFlagQuery = useFeatureFlagsQuery();

  if (
    googleOAuth.isValidRedirect ||
    wirecardOAuth.isValidRedirect ||
    panelOAuth.isValidRedirect ||
    featureFlagQuery.isLoading
  ) {
    return (
      <Center
        bg="white"
        position="absolute"
        top={0}
        left={0}
        right={0}
        h="100vh">
        <EllipsisLoading color="brand.500" />
      </Center>
    );
  }

  if (featureFlagQuery.isError) {
    return (
      <Center color="gray.300" flexDirection="column" h="calc(100vh - 100px)">
        <Icon as={IoAlertCircleOutline} boxSize={20} />
        <Text fontSize="lg" maxW="md" textAlign="center" m={4}>
          Parece que algo deu errado
        </Text>
        <Button
          colorScheme="gray"
          rounded="full"
          onClick={featureFlagQuery.refetch}>
          Recarregar
        </Button>
      </Center>
    );
  }

  return (
    <>
      <AppRoutes />

      <AuthModal />
    </>
  );
};

const App = () => {
  return (
    <ChakraProvider>
      <SentryProvider>
        <ReactQueryProvider>
          <ModalProvider>
            {/* <TawkMessengerReact
              propertyId={Integrations.TAWK_TO_ID}
              widgetId="default"
            /> */}
            <ScrollToTop />
            <Pages />
          </ModalProvider>
        </ReactQueryProvider>
      </SentryProvider>
    </ChakraProvider>
  );
};

export default App;
