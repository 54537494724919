import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { EllipsisLoading } from './components/loadings';
import { Box } from '@chakra-ui/react';

import { RoutesWithHeader } from 'src/Layouts/WithHeader';
import EventManagementRoutes from 'src/Pages/EventManagement/Routes';

const PasswordRecover = lazy(
  () =>
    import(
      /* webpackChunkName: "PasswordRecoverPage" */
      './Pages/PasswordRecoverNew'
    ),
);

const EventPos = lazy(
  () =>
    import(
      /* webpackChunkName: "EventPosPage" */
      './Pages/EventPos'
    ),
);

const MeEvent = lazy(
  () =>
    import(
      /* webpackChunkName: "MeEventPage" */
      './Pages/Me/Event'
    ),
);

const MeEvents = lazy(
  () =>
    import(
      /* webpackChunkName: "MeEventsPage" */
      './Pages/Me/Events'
    ),
);

const EventsCoupon = lazy(
  () =>
    import(
      /* webpackChunkName: "EventsCouponPage" */
      './Pages/EventsCoupon'
    ),
);

const MeAffiliations = lazy(
  () =>
    import(
      /* webpackChunkName: "MeAffiliationsPage" */
      './Pages/Me/Affiliations'
    ),
);

const MeAccount = lazy(
  () =>
    import(
      /* webpackChunkName: "MeAccountPage" */
      './Pages/Me/PersonalData'
    ),
);

const Confirm = lazy(
  () =>
    import(
      /* webpackChunkName: "ConfirmPage" */
      './Pages/Confirm'
    ),
);

const PromoterNew = lazy(
  () =>
    import(
      /* webpackChunkName: "PromoterNewPage" */
      './Pages/Promoter/New'
    ),
);

const BillSection = lazy(
  () =>
    import(
      /* webpackChunkName: "BillSectionPage" */
      './Pages/Me/Panel/Sections/BillSection'
    ),
);

const CouponSection = lazy(
  () =>
    import(
      /* webpackChunkName: "CouponSectionPage" */
      './Pages/Me/Panel/Sections/CouponSection'
    ),
);

const ClientsSection = lazy(
  () =>
    import(
      /* webpackChunkName: "ClientsSectionPage" */
      './Pages/Me/Panel/Sections/ClientListSection'
    ),
);

const LandingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "LandingPage" */
      './Pages/Landing'
    ),
);

const FinancialDashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "FinancialDashboard" */
      'src/Pages/FinancialDashboard'
    ),
);
const MeTickets = lazy(
  () =>
    import(
      /* webpackChunkName: "MeTicketsPage" */
      './Pages/Me/Tickets'
    ),
);

const PromoterSendTickets = lazy(
  () =>
    import(
      /* webpackChunkName: "PromoterSendTickets" */
      './Pages/Promoter/SendTickets'
    ),
);

const PromoterSoldTickets = lazy(
  () =>
    import(
      /* webpackChunkName: "PromoterSoldTickets" */
      './Pages/Promoter/SoldTickets'
    ),
);

const ColaboratorRoute = lazy(
  () =>
    import(
      /* webpackChunkName: "ColaboratorPage" */
      'src/Pages/Colaborator'
    ),
);

const Home = lazy(
  () =>
    import(
      /* webpackChunkName: "HomePage" */
      'src/Pages/Home'
    ),
);

const HelpPageRoutes = lazy(
  () =>
    import(
      /* webpackChunkName: "HelpPage" */
      'src/Pages/HelpPage/Routes'
    ),
);

const EventNew = lazy(
  () =>
    import(
      /* webpackChunkName: "CreateNewEvent" */
      './Pages/Event'
    ),
);

const ProducerProfile = lazy(
  () =>
    import(
      /* webpackChunkName: "ProducerProfile" */
      'src/Pages/Producer/Profile'
    ),
);

const ProducerNew = lazy(
  () =>
    import(
      /* webpackChunkName: "ProducerNew" */
      'src/Pages/Producer/New/index'
    ),
);

const ProducerEdit = lazy(
  () =>
    import(
      /* webpackChunkName: "ProducerEdit" */
      'src/Pages/Producer/Edit'
    ),
);

const Privacy = lazy(
  () =>
    import(
      /* webpackChunkName: "PrivacyPage" */
      'src/Pages/Docs/Privacy'
    ),
);

const Terms = lazy(
  () =>
    import(
      /* webpackChunkName: "TermsPage" */
      'src/Pages/Docs/Terms'
    ),
);

const LoadingPage = () => (
  <Box position="fixed" top="50%" left="50%" transform="translate(-50%,-50%)">
    <EllipsisLoading color="brand.500" />
  </Box>
);

export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/ajuda/*"
        element={
          <Suspense
            fallback={
              <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%,-50%)">
                <EllipsisLoading color="brand.500" />
              </Box>
            }>
            <HelpPageRoutes />
          </Suspense>
        }
      />
      <Route
        path="/:userLevel/event/:eventID/*"
        element={<EventManagementRoutes />}
      />

      <Route
        path="/password-update/:email/:code"
        element={
          <Suspense
            fallback={
              <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%,-50%)">
                <EllipsisLoading color="brand.500" />
              </Box>
            }>
            <PasswordRecover />
          </Suspense>
        }
      />

      <Route
        path="/solucoes"
        element={
          <Suspense
            fallback={
              <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%,-50%)">
                <EllipsisLoading color="brand.500" />
              </Box>
            }>
            <LandingPage />
          </Suspense>
        }
      />

      {/* Criar / Editar eventos */}
      <Route
        path="me/events/:id/*"
        element={
          <Suspense fallback={<LoadingPage />}>
            <MeEvent />
          </Suspense>
        }
      />

      {/* ROUTES WITH HEADER  */}
      <Route path="/" element={<RoutesWithHeader />}>
        <Route
          path="event/:eventID/*"
          element={
            <Suspense fallback={<LoadingPage />}>
              <EventNew />
            </Suspense>
          }
        />
        <Route path="event-pos/:eventID" element={<EventPos />} />
        <Route
          path="me/account"
          element={
            <Suspense fallback={<LoadingPage />}>
              <MeAccount />
            </Suspense>
          }
        />
        <Route
          path="me/affiliations"
          element={
            <Suspense fallback={<LoadingPage />}>
              <MeAffiliations />
            </Suspense>
          }
        />
        <Route
          path="me/events"
          element={
            <Suspense fallback={<LoadingPage />}>
              <MeEvents />
            </Suspense>
          }
        />
        <Route
          path="me/tickets"
          element={
            <Suspense fallback={<LoadingPage />}>
              <MeTickets />
            </Suspense>
          }
        />
        <Route
          path="painel/faturas"
          element={
            <Suspense fallback={<LoadingPage />}>
              <BillSection />
            </Suspense>
          }
        />
        <Route
          path="painel/clientes"
          element={
            <Suspense fallback={<LoadingPage />}>
              <ClientsSection />
            </Suspense>
          }
        />
        <Route
          path="painel/cupons-recorrentes"
          element={
            <Suspense fallback={<LoadingPage />}>
              <CouponSection />
            </Suspense>
          }
        />
        <Route
          path="terms"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Terms />
            </Suspense>
          }
        />
        <Route
          path="privacy"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Privacy />
            </Suspense>
          }
        />
        <Route
          path="confirm/:token"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Confirm />
            </Suspense>
          }
        />
        <Route
          path="newpromoter"
          element={
            <Suspense fallback={<LoadingPage />}>
              <PromoterNew />
            </Suspense>
          }
        />
        <Route
          path="novocolaborador"
          element={
            <Suspense fallback={<LoadingPage />}>
              <ColaboratorRoute />
            </Suspense>
          }
        />
        <Route
          path="organizador/novo"
          element={
            <Suspense fallback={<LoadingPage />}>
              <ProducerNew />
            </Suspense>
          }
        />
        <Route
          path="organizador/editar"
          element={
            <Suspense fallback={<LoadingPage />}>
              <ProducerEdit />
            </Suspense>
          }
        />
        <Route
          path="send-tickets/:eventID"
          element={
            <Suspense fallback={<LoadingPage />}>
              <PromoterSendTickets />
            </Suspense>
          }
        />
        <Route
          path="ticket/:ticketGroupId"
          element={
            <Suspense fallback={<LoadingPage />}>
              <PromoterSoldTickets />
            </Suspense>
          }
        />
        <Route
          path="coupon/:coupon/events/:userId"
          element={
            <Suspense fallback={<LoadingPage />}>
              <EventsCoupon />
            </Suspense>
          }
        />
        <Route
          path="financeiro"
          element={
            <Suspense fallback={<LoadingPage />}>
              <FinancialDashboard />
            </Suspense>
          }
        />
        <Route
          index
          element={
            <Suspense fallback={<LoadingPage />}>
              <Home />
            </Suspense>
          }
        />
      </Route>

      {/* TODAS AS ROTAS DEVEM SER ACIMA DESTA */}
      <Route
        path="/:profileSlug"
        element={
          <Suspense fallback={<LoadingPage />}>
            <ProducerProfile />
          </Suspense>
        }
      />
    </Routes>
  );
};
