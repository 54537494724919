import {
  IoPencil,
  IoTimeOutline,
  IoCloseCircleOutline,
  IoBanOutline,
  IoInformationCircleOutline,
} from 'react-icons/io5';
import { Urls } from 'src/appConstants';
import { TicketStatus, EventStatus, Uid } from 'src/types/dto';
import { TicketCardVariant, EventCardVariant } from 'src/types/ui';

export const EventStatuses = {
  DRAFT: 0,
  PENDING: 1,
  PUBLISHED: 2,
  CANCELLED: 3,
  SUSPENDED: 4,
  PUBLISH_DENIED: 5,
  CLOSED: 6,
  PENDING_WIRECARD: 7,
};

// -- 1: confirmado; 2: cancelado; 3: utilizado
export const TicketStatuses = {
  CONFIRMED: 1,
  CANCELLED: 2,
  USED: 3,
  ANALYSIS: 4,
  PASSPORT: 5,
  CONDITIONED: 6,
};

export const TicketCardVariants: TicketCardVariant = {
  [TicketStatus.Confirmed]: {
    colorScheme: 'blue',
    title: 'Ingresso confirmado',
  },
  [TicketStatus.Cancelled]: {
    colorScheme: 'red',
    title: 'Ingresso cancelado',
  },
  [TicketStatus.Used]: {
    colorScheme: 'red',
    title: 'Ingresso utilizado',
  },
  [TicketStatus.Analysis]: {
    colorScheme: 'orange',
    title: 'Pagamento em análise',
  },
  [TicketStatus.Passport]: {
    colorScheme: 'blue',
    title: 'Passaporte',
  },
  [TicketStatus.Conditioned]: {
    colorScheme: 'blue',
    title: 'Condicionado',
  },
};

export const EventCardVariants: EventCardVariant = {
  [EventStatus.Draft]: {
    colorScheme: 'gray',
    icon: IoPencil,
    text: 'O evento ainda não foi terminado e se encontra em rascunho.',
    title: 'Rascunho',
  },
  [EventStatus.Pending]: {
    colorScheme: 'orange',
    icon: IoTimeOutline,
    text: 'O evento será analisado para então ser publicado.',
    title: 'Publicação Pendente',
  },
  [EventStatus.Published]: {
    colorScheme: 'green',
    icon: IoInformationCircleOutline,
    text: 'O evento está publicado.',
    title: 'Evento Publicado',
  },
  [EventStatus.Cancelled]: {
    colorScheme: 'red',
    icon: IoCloseCircleOutline,
    text: 'O evento foi cancelado.',
    title: 'Evento Cancelado',
  },
  [EventStatus.Suspended]: {
    colorScheme: 'red',
    icon: IoCloseCircleOutline,
    text: 'Vendas online encerradas. Consulte disponibilidade na bilheteria do evento.',
    title: 'Vendas online Encerradas',
  },
  [EventStatus.PublishDenied]: {
    colorScheme: 'red',
    icon: IoBanOutline,
    text: 'O evento teve sua publicação negada.',
    title: 'Publicação Negada',
  },
  [EventStatus.Closed]: {
    colorScheme: 'red',
    icon: IoCloseCircleOutline,
    text: 'A venda desse evento já foi encerrada.',
    title: 'Evento Encerrado',
  },
  [EventStatus.PendingWirecard]: {
    colorScheme: 'orange',
    icon: IoCloseCircleOutline,
    text: 'Este evento não está liberado para vendas online. Para habilitar, vincule uma conta de repasse em "Meus eventos".',
    title: 'Evento sem vendas online',
  },
};

export const getEventBannerUrl = ({
  eventId,
  size = 's',
}: {
  eventId: Uid;
  size?: 's' | 'l';
}) => {
  const unicId = Date.now();
  return `${Urls.IMG}/event/${eventId}/${size}?uniqueId=${unicId}`;
};

export const getUserImgUrl = ({ userId }: { userId: number }) => {
  const unicId = Date.now();

  return `${Urls.IMG}/user/${userId}?uniqueId=${unicId}`;
};
