import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import MainMenu from 'src/legacy/Components/Menus/MainMenu';

import { ReactComponent as Logo } from 'src/assets/new_uticket_logo.svg';

import './styles.scss';
import { Button, IconButton, HStack } from '@chakra-ui/react';
import { IoMenu } from 'react-icons/io5';

const Header = () => {
  const navigate = useNavigate();
  const [showMainMenu, setShowMainMenu] = React.useState(false);

  const navigateToLandingPage = () => {
    navigate('/solucoes');
  };

  const navigateToEventNew = () => {
    navigate('/me/events/new');
  };

  const onOpenMainMenu = () => {
    setShowMainMenu(true);
  };

  const onCloseMainMenu = () => {
    setShowMainMenu(false);
  };

  return (
    <header className="main-header">
      <div className="container header">
        <RouterLink
          to="/"
          aria-label="Ir para página inicial do Uticket"
          title="Uticket seu evento começa aqui">
          <Logo height="35px" />
        </RouterLink>
        <HStack float="right">
          <Button
            variant="ghost"
            rounded="full"
            fontWeight="bold"
            color="black"
            fontSize="sm"
            display="inline-flex"
            backgroundColor="gray.50"
            onClick={navigateToEventNew}>
            Criar Evento
          </Button>
          <IconButton
            icon={<IoMenu size="30" />}
            variant="ghost"
            color="black"
            rounded="full"
            onClick={onOpenMainMenu}
            aria-label="Abrir menu lateral"
          />
        </HStack>
      </div>
      <MainMenu show={showMainMenu} onDismiss={onCloseMainMenu} />
    </header>
  );
};

export default Header;
